import './chapter-one.scss'
import crackright from '../../assets/images/chapter-one/crack-right.png'
import building from "../../assets/images/chapter-one/building.png"

export const ChapterOne = () => {
    return (
        <div className="cbc-chapter-one">
            <img src={crackright} alt="CrackRight" className="cbc-crack-right"/>
            <div className="chapter-header">
                <div className="chapter-title">
                    <h2>Chapter 1</h2>
                    <h3>The Gathering of <br className="mobile"/>Economic Guardians</h3>
                </div>
                <img src={building} alt={"Building"} className="cbc-building-mobile"/>
            </div>
            <div className="chapter-content">
                <p>
                    <br />
                    The economic landscape of Memeworld was facing unprecedented challenges. Volatility in the Milk and
                    Yarn markets, a sudden scarcity of Cardboard Boxes, and an inflation in Tuna prices threatened to
                    disrupt the purrfect harmony.<br/><br/> Realizing the gravity of the situation, the heads of the world's
                    leading economies convened at the classified location, the headquarters of CBOC.
                </p>
                <img src={building} alt={"Event"} className="cbc-building desktop"/>
            </div>
        </div>
    )
}