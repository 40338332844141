import './navbar.scss'
import {ReactComponent as XIcon} from "../../assets/icons/navbar/x.svg"
import {ReactComponent as TelegramIcon} from "../../assets/icons/navbar/telegram.svg"
import {Button} from "../button/button";


export const Navbar = () => {
    return (
        <nav className="cbc-navbar">
            <h2>Central Bank Of Cats</h2>
            <section className="socials">
                <Button icon={<XIcon/>} href={"https://twitter.com/cboc_sol"}/>
                <Button icon={<TelegramIcon/>} href={"https://t.me/cbocsol"}/>
            </section>
            <Button text={"Buy Now"} />
        </nav>
    )
}