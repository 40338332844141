import './election.scss'
import {Button} from "../button/button";
import biden from "../../assets/images/election/biden.png"
import trump from "../../assets/images/election/trump.png"
import dots from "../../assets/images/election/dots.png"
import starleft from "../../assets/images/election/star-left.png"
import starright from "../../assets/images/election/star-right.png"
import boltleft from "../../assets/images/election/bolt-left.png"
import boltright from "../../assets/images/election/bolt-right.png"

export const Election = () => {
    return (
        <div className={"cbc-election"}>
            <img src={biden} alt={"Biden"} className={"cbc-biden"}/>
            <img src={trump} alt={"Trump"} className={"cbc-trump"}/>
            <img src={dots} alt={"Dots"} className={"cbc-dots"}/>
            <img src={starleft} alt={"StarLeft"} className={"cbc-star-left"}/>
            <img src={starright} alt={"StarRight"} className={"cbc-star-right"}/>
            <img src={boltleft} alt={"BoltLeft"} className={"cbc-bolt-left"}/>
            <img src={boltright} alt={"BoltRight"} className={"cbc-bolt-right"}/>

            <div className={"content"}>
                <h3>Who will win?</h3>
                <p>Place your bets on $CBOC</p>
            </div>
            <Button text={"Coming soon"}/>
        </div>
    )
}