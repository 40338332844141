import './tokenomics.scss';
import ring from '../../assets/images/tokenomics/ring.png'
import cat from '../../assets/images/tokenomics/cat.png'
import starcloud from '../../assets/images/tokenomics/starcloud.png'
import dots from '../../assets/images/tokenomics/dots.png'
import crackright from '../../assets/images/tokenomics/crack-right.png'

export const Tokenomics = () => {
    return (
        <div className="cbc-tokenomics">
            <img src={crackright} alt="CrackRight" className="cbc-crack-right"/>
            <img src={dots} alt="Dots" className="cbc-dots"/>
            <img src={cat} alt="Cat" className="cbc-cat"/>
            <img src={starcloud} alt={"StarCloud"} className={"cbc-starcloud"} />
            <div className="title">
                <h3>Tokenomics</h3>
                <p>Federal Reserve</p>
            </div>
            <div className="column">
                <h4>Total Supply</h4>
                <p>1.000.000.000 $CBOC</p>
            </div>
            <div className="cbc-ring">
                <img src={ring} alt="Ring"/>
                <span>
                    100% <br/>in LP
                </span>
            </div>
        </div>
    )
}