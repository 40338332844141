import './footer.scss'
import cat from '../../assets/images/footer/cat.png'
import crackright from '../../assets/images/footer/crack-right.png'
import crackrightmobile from '../../assets/images/footer/crack-right-mobile.png'
import {Button} from "../button/button";
import {ReactComponent as XIcon} from '../../assets/icons/navbar/x.svg'
import {ReactComponent as TelegramIcon} from '../../assets/icons/navbar/telegram.svg'

export const Footer = () => {
    return (
        <footer className="cbc-footer">
            <div className="desktop">
                <img src={cat} alt="Cat" className="cbc-cat"/>
                <img src={crackright} alt={"CrackRight"} className={"cbc-crack-right"} />
                <p className="cbc-tale">
                    This tale of the Central Bank of Cats illustrates how humor, unity, and innovative thinking can
                    overcome economic challenges, creating a world where prosperity and laughter go hand in hand.
                </p>
                <nav>
                    <h2>Central Bank Of Cats</h2>
                    <section>
                        <a href="#">HOW TO BUY</a>
                        <a href="#">CONTACT US</a>
                        <a href="#">STORY</a>
                    </section>
                    <Button text="Buy Now"/>
                </nav>
                <div className="copyright">
                    <p>© 2024 by Central Bank of Cats. All rights reserved!</p>
                    <section className="socials">
                        <Button icon={<XIcon/>} href={"https://twitter.com/cboc_sol"}/>
                        <Button icon={<TelegramIcon/>} href={"https://t.me/cbocsol"}/>
                    </section>
                </div>
            </div>

            <div className="mobile">
                <img src={cat} alt="Cat" className="cbc-cat"/>
                <img src={crackrightmobile} alt={"CrackRightMobile"} className={"cbc-crack-right-mobile"}/>
                <p className="cbc-tale">
                    This tale of the Central Bank of Cats illustrates how humor, unity, and innovative thinking can
                    overcome economic challenges, creating a world where prosperity and laughter go hand in hand.
                </p>
                <nav>
                    <h2>Central Bank Of Cats</h2>
                    <section>
                        <a href="#">HOW TO BUY</a>
                        <a href="#">CONTACT US</a>
                        <a href="#">STORY</a>
                    </section>
                <div className="below">
                    <Button text="Buy Now"/>
                    <section className="socials">
                        <Button icon={<XIcon/>} href={"https://twitter.com/cboc_sol"}/>
                        <Button icon={<TelegramIcon/>} href={"https://t.me/cbocsol"}/>
                    </section>
                </div>
                </nav>
                <div className="copyright">
                    <p>© 2024 by Central Bank of Cats. All rights reserved!</p>
                </div>
            </div>
        </footer>
    )
}
