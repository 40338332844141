import "./Game.scss";
import Birds from "../game-components/Birds";
import Bricks from "../game-components/Bricks";
import Clouds from "../game-components/Clouds";
import Mario from "../game-components/Mario";
import Obstacles from "../game-components/Obstacles";
import Sun from "../game-components/Sun";
import KeyMessage from "../game-components/KeyMessage";
import LoadingScreen from "../game-components/LoadingScreen";

// redux
import {useSelector} from "react-redux";
import Score from "../game-components/Score";
import MobileControls from "../game-components/MobileControls";
import smoke from "../../assets/images/game/smoke.png"

function Game() {
  const isPlay = useSelector((state) => state.engine.play);
  const isLoading = useSelector((state) => state.engine.loadingScreen);
  return (
    <div className="cbc-game-container">
        <img src={smoke} alt="Smoke" className="cbc-smoke" />
        <p className="title">PLAY WITH CATS!</p>
      {isLoading && <LoadingScreen />}
      <div className="Game">
        {!isPlay && <KeyMessage />}
        <Bricks />
        <Mario />
        <Sun />
        <Clouds />
        <Birds />
        <Obstacles />
        <Score />
      </div>
      <MobileControls />

    </div>
  );
}

export default Game;
