import './chapter-three.scss';
import mooncat from "../../assets/images/chapter-three/mooncat.png";
import lightning from "../../assets/images/chapter-three/lightning.png";
import lightningmobile from "../../assets/images/chapter-three/lightning-mobile.png";
import crackrightmobile from "../../assets/images/chapter-three/crack-right-mobile.png";
import crackleft from "../../assets/images/chapter-three/crack-left.png";

export const ChapterThree = () => {
    return (
        <div className="cbc-chapter-three">
            <div className="desktop">
                <img src={lightning} alt={"Lightning"} className={"cbc-lightning"}/>
                <img src={crackleft} alt={"CrackLeft"} className={"cbc-crack-left"}/>
                <div className="chapter-title">
                    <h2>Chapter 3</h2>
                    <h3>The Plan Unfolds</h3>
                </div>
                <div className="chapter-content">
                    <p>
                        <br/>The head of BOJ announced a "Quantitative Purring" program, where $CBOC tokens would be
                        distributed to stimulate economic activity and encourage investment in the new digital currency.<br/><br/>
                        The head of BOE, launched the "Feline Forward Guidance" initiative, using $CBOC tokens to
                        incentivise sustainable economic practices and support the growth of the local industries.<br/><br/> The
                        head of Fed, always bold in his approach, introduced the "Operation Catnip Twist," a scheme to
                        swap short-term fish stocks for long-term tuna bonds, thus ensuring a steady supply of tuna thus
                        stabilising the economy.<br/><br/>
                    </p>
                    <img src={mooncat} alt={"MoonCat"} className={"cbc-mooncat"}/>
                </div>
            </div>
            <div className="mobile">
                <img src={lightningmobile} alt={"LightningMobile"} className={"cbc-lightning-mobile"}/>
                <img src={crackrightmobile} alt={"CrackRightMobile"} className={"cbc-crack-right-mobile"}/>
                <div className="chapter-header">
                    <div className="chapter-title">
                        <h2>Chapter 3</h2>
                        <h3>The Plan Unfolds</h3>
                    </div>
                    <img src={mooncat} alt={"Cat"} className={"cbc-mooncat-mobile"}/>
                </div>
                <p>
                    <br/>The head of BOJ announced a "Quantitative Purring" program, where $CBOC tokens would be
                    distributed to stimulate economic activity and encourage investment in the new digital
                    currency.<br/><br/>
                    The head of BOE, launched the "Feline Forward Guidance" initiative, using $CBOC tokens to
                    incentivise sustainable economic practices and support the growth of the local
                    industries.<br/><br/> The
                    head of Fed, always bold in his approach, introduced the "Operation Catnip Twist," a scheme to
                    swap short-term fish stocks for long-term tuna bonds, thus ensuring a steady supply of tuna thus
                    stabilising the economy.<br/><br/>
                </p>
            </div>
        </div>
    )
}
