import "./landing.scss"
import cat from "../../assets/images/landing/cat.png"
import lightningbolt from "../../assets/images/landing/lightning-bolt.png"
import thundercloud from "../../assets/images/landing/thunder-cloud.png"
import shootingstar from "../../assets/images/landing/shooting-star.png"
import bottomclip from "../../assets/images/landing/bottom-clip.png"
import {ReactComponent as XIcon} from "../../assets/icons/navbar/x.svg"
import {ReactComponent as TelegramIcon} from "../../assets/icons/navbar/telegram.svg"

import './landing.scss'
import {Navbar} from "../navbar/navbar";
import {Button} from "../button/button";

export const Landing = () => {
    return (
        <div className="cbc-landing">
            <Navbar/>
            <h1 className="cbc-text">Central Bank<br/> Of Cats</h1>
            <img src={cat} alt="Cat" className="cbc-cat"/>
            <img src={lightningbolt} alt="Lightning-Bolt" className="cbc-lightning-bolt"/>
            <img src={thundercloud} alt="Thunder-Cloud" className="cbc-thunder-cloud updown"/>
            <img src={shootingstar} alt="Shooting-Star" className="cbc-shooting-star"/>
            <div src={bottomclip} alt="Bottom-Clip" className="cbc-bottom-clip"/>
            <div className="cbc-socials">
                <Button icon={<XIcon/>} href={"https://twitter.com/cboc_sol"}/>
                <Button icon={<TelegramIcon/>} href={"https://t.me/cbocsol"}/>
            </div>
        </div>
    )
}
