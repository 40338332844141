import './styles/globals.scss'
import './cbc.scss'
import {Landing} from "./components/landing/landing";
import {ChapterOne} from "./components/chapter-one/chapter-one";
import {ChapterTwo} from "./components/chapter-two/chapter-two";
import {HowToBuy} from "./components/how-to-buy/how-to-buy";
import {Toaster} from "react-hot-toast";
import {ChapterThree} from "./components/chapter-three/chapter-three";
import {FrequentlyAskedQuestions} from "./components/frequently-asked-questions/frequently-asked-questions";
import {ChapterFour} from "./components/chapter-four/chapter-four";
import {Footer} from "./components/footer/footer";
import Game from "./components/Game/Game";
import {Tokenomics} from "./components/tokenomics/tokenomics";
import {CEX} from "./components/cex/cex";
import {Privacy} from "./components/privacy/privacy";
import {Election} from "./components/election/election";

export const CBC = () => {
    return (
        <div className="cbc-home">
            <Toaster/>
            <Landing/>
            <ChapterOne/>
            <CEX/>
            <ChapterTwo/>
            <Privacy/>
            <ChapterThree/>
            <HowToBuy/>
                <Election />
            <ChapterFour/>
            <Tokenomics/>
            <FrequentlyAskedQuestions/>
            <Game/>
            <Footer/>
        </div>
    );
}
