import './privacy.scss'
import starcloud from '../../assets/images/privacy/starcloud.png';
import star from '../../assets/images/privacy/star.png';
import rocket from '../../assets/images/privacy/rocket.png';
import dots from '../../assets/images/privacy/dots.png';
import flameupper from '../../assets/images/privacy/flame-upper.png';
import flamebeneath from '../../assets/images/privacy/flame-beneath.png';
import dotsmobile from '../../assets/images/privacy/dotsmobile.png';

import {Button} from "../button/button";

export const Privacy = () => {
    return (
        <div className={"cbc-privacy"}>
            <img src={dots} alt={"Dots"} className={"cbc-dots"}/>
            <img src={dotsmobile} alt={"DotsMobile"} className={"cbc-dots-mobile"}/>
            <img src={starcloud} alt={"StarCloud"} className={"cbc-star-cloud"}/>
            <img src={star} alt={"StarRight"} className={"cbc-star-right"}/>
            <img src={star} alt={"StarLeft"} className={"cbc-star-left"}/>
            <img src={rocket} alt={"Rocket"} className={"cbc-rocket"}/>
            <img src={flameupper} alt={"FlameUpper"} className={"cbc-flame-upper"}/>
            <img src={flamebeneath} alt={"FlameBeneath"} className={"cbc-flame-beneath"}/>
            <div className={"content"}>
            <h2>The Central Bank Of Cats puts the utmost value on privacy!</h2>
            <h3>Purchase private VPN's through our automated<br/> blockchain powered TG BOT.</h3>
            <Button text={"Reserve Now"} />
            </div>
        </div>
    )
}