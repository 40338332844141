import './cex.scss';
import cat from '../../assets/images/cex/cat.png'
import thunder from '../../assets/images/cex/thunder.png'
import thunderreverse from '../../assets/images/cex/thunder-reverse.png'
import cloud from '../../assets/images/cex/cloud.png'
import thundercloud from '../../assets/images/cex/thundercloud.png'
import like from '../../assets/images/cex/like.png'
import likereverse from '../../assets/images/cex/like-reverse.png'
import raydium from '../../assets/images/cex/raydium.png'
import dexscreener from '../../assets/images/cex/dex-screener.png'
import jupiter from '../../assets/images/cex/jupiter.png'
import dextools from '../../assets/images/cex/dex-tools.png'
import dots from '../../assets/images/cex/dots.png'
import particles from '../../assets/images/cex/particles.png'

export const CEX = () => {
    return (
        <div className={"cbc-cex"}>
            <img src={dots} alt={"Dots"} className={"cbc-dots"}/>
            <img src={particles} alt={"Particles"} className={"cbc-particles"}/>
            <img src={thunder} alt={"Thunder"} className={"cbc-thunder"}/>
            <img src={thunderreverse} alt={"ThunderReverse"} className={"cbc-thunder-reverse"}/>
            <img src={cloud} alt={"Cloud"} className={"cbc-cloud"}/>
            <img src={thundercloud} alt={"ThunderCloud"} className={"cbc-thunder-cloud"}/>

            <ul>
                <img src={cat} alt={"Cat"} className={"cbc-cat"}/>
                <li>
                    <img src={raydium} alt={"Raydium"}/>
                </li>
                <li>
                    <img src={dexscreener} alt={"DexScreener"}/>
                </li>
                <li>
                    <img src={jupiter} alt={"Jupiter"}/>
                </li>
                <li>
                    <img src={dextools} alt={"DexTools"}/>
                </li>
            </ul>
            <div className={"cbc-coming-soon"}>
                <img src={likereverse} alt={"LikeReverse"} className={"cbc-like-reverse"}/>
                <h3>CEX's Coming Soon</h3>
                <img src={like} alt={"Like"} className={"cbc-like"}/>
            </div>
        </div>
    )
}