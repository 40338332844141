import './chapter-two.scss'
import crackleft from "../../assets/images/chapter-two/crack-left.png"
import cats from "../../assets/images/chapter-two/cats.png"

export const ChapterTwo = () => {
    return (
        <div className="cbc-chapter-two">
            <div className="desktop">
                <img src={cats} alt="Cat" className="cbc-cat-desktop"/>
                <img src={crackleft} alt="CrackLeft" className="cbc-crack-left"/>
                <div className="column">
                    <div className="chapter-title">
                        <h2>Chapter 2</h2>
                        <h3>The Revelation of the ECB</h3>
                    </div>
                    <p>
                        <br/>Amidst the council chair of the FED proposed a ground-breaking solution… The Central Bank
                        of
                        Cats! A digital currency backed by the most powerful economies in the world aimed to become the
                        no1 asset of exchange in todays economy.<br/><br/>

                        He elaborated on how the $CBOC token, powered by the collective faith and humor of Memeworld’s
                        citizens, could serve as a stabilising force.<br/>
                    </p>
                </div>
            </div>
            <div className="mobile">
                <div className="chapter-header">
                    <img src={cats} alt="Cat" className={"cbc-cat-mobile"}/>
                    <div className="chapter-title">
                        <h2>Chapter <br/>2</h2>
                        <h3>The Revelation <br/>of the ECB</h3>
                    </div>
                </div>
                <p>
                    <br/>
                    Amidst the council the head of ECB proposed a ground-breaking solution… The Central Bank of Cats! A
                    digital currency backed by the most powerful economies in the world aimed to become the no1 asset of
                    exchange in todays economy.<br/><br/>

                    He elaborated on how the $CBOC token, powered by the collective faith and humor of Memeworld’s
                    citizens, could serve as a stabilising force.<br/>
                </p>
                <img src={crackleft} alt="CrackLeft" className="cbc-crack-left"/>
            </div>

        </div>
    )
}
