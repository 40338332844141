import {useEffect, useState} from "react";
import "./LoadingScreen.css";
import {setLoadingScreen} from "../../redux/engineSlice";
import {useDispatch} from "react-redux";

const LoadingScreen = () => {
  const [isReady, setIsReady] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setIsReady(true);
 dispatch(setLoadingScreen(false))

    }, 0);
  }, []);

  return (
    <div className="loading-screen-container">
   {/*   <img src={MarioCharacter} alt="" className="loading-mario" />
      {!isReady && <h1 className="loading-title">Loading...</h1>}
      {isReady && (
        <button
          className="enter-button"
        >
          ENTER
        </button>
      )}*/}
    </div>
  );
};
export default LoadingScreen;
