import './chapter-four.scss'
import moneycat from "../../assets/images/chapter-four/moneycat.png";

export const ChapterFour = () => {
    return (
        <div className="cbc-chapter-four">
            <div className="desktop">
                <img src={moneycat} alt="Cat"/>
                <div className="column">
                    <div className="chapter-title">
                        <h2>Chapter 4</h2>
                        <h3>The Triumph of the Central Bank of Cats</h3>
                    </div>
                    <p>
                        <br/>As the heads implemented these innovative policies, the economies of the world began to
                        flourish. The $CBOC token, with its meme-based incentive and economic utility, became a symbol of
                        resilience and unity.<br/><br/>

                        Citizens across world embraced the $CBOC token, creating memes that celebrated the heads efforts
                        and the newfound economic stability. From the mountains of Japan to the bustling streets of New
                        York, laughter and prosperity reigned once again.<br/><br/>
                    </p>
                </div>
            </div>
            <div className="mobile">
                <div className="chapter-header">
                    <img src={moneycat} alt="Cat"/>
                    <div className="chapter-title">
                        <h2>Chapter 4</h2>
                        <h3>The Triumph of<br/> the Central Bank<br/> of Cats</h3>
                    </div>
                </div>
                <p>
                    <br/>
                    As the heads implemented these innovative policies, the economies of the world began to flourish.
                    The $CBOC token, with its meme-based incentive and economic utility, became a symbol of resilience
                    and unity.<br/><br/>

                    Citizens across world embraced the $CBOC token, creating memes that celebrated the heads efforts and
                    the newfound economic stability. From the mountains of Japan to the bustling streets of New York,
                    laughter and prosperity reigned once again.<br/>
                </p>
            </div>
        </div>
    )
}